<template>
  <content-container></content-container>
</template>

<script>
import ContentContainer from "@/components/ContentContainer";

export default {
  name: "VeContainer",
  components: {ContentContainer},
}
</script>

<style scoped>

</style>