<template>
  <auto-container></auto-container>
</template>

<script>
import AutoContainer from "@/components/AutoContainer";

export default {
  name: "AutoLpContainer",
  components: {AutoContainer},
}
</script>

<style scoped>

</style>