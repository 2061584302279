<template>
  <sb-container></sb-container>
</template>

<script>
import SbContainer from "@/components/SbContainer";

export default {
  name: "StakeContainer",
  components: {SbContainer},
}
</script>

<style scoped>

</style>