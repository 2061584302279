<template>
  <dust-container></dust-container>
</template>

<script>
import DustContainer from "@/components/DustContainer";

export default {
  name: "DSTRContainer",
  components: {DustContainer},
}
</script>

<style scoped>

</style>