<template>
  <pangolin-container></pangolin-container>
</template>

<script>

import PangolinContainer from "@/components/PangolinContainer";
export default {
  name: "SwapContainer",
  components: {PangolinContainer},
}
</script>

<style scoped>

</style>