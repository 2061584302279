import { createApp } from 'vue';
import App from './App.vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import Vuex from 'vuex';
import web3ModalStore from "@/store/modules/web3Modal";
import router from './router';

const store = new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        web3Modal: web3ModalStore,
    }
})

createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).mount('#app')

export default store;